import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import MediaQuery from 'react-responsive';
import moment from 'moment';

import SEO from '../components/seo'
import Layout from '../components/layout'
import styled from 'styled-components'
import submitBtn from '../assets/images/submit-button.svg'

const BookingStyles = styled.div`
  height: 100%;

  div {
    margin: 0 auto;
  }

  p {
    color: var(--white);
  }

  .with-bg {
    min-height: 66vh;

    @media screen and (min-width: 375px) {
      min-height: 70vh;
    }

    @media screen and (min-width: 768px) {
      min-height: 75vh;
      padding-bottom: 90px;
    }
  }

  @media screen and (min-width: 1024px) {
    .with-bg {
      min-height: auto;
    }
  }

  .booking-form {
    color: white;
    font-size: 40px;
  }

  @media (min-width: 768px) {
    .booking-form {
      font-size: 45px;
    }
  }

  .length-of-stay-input {
    appearance: none;
    color: #C6C6C6;
    background: transparent;
    border-color: transparent;
    border-radius: 0;
    border-width: 0 0 1px 0;
    cursor: pointer;
    display: inline-block;
    margin: 0 0.25em;
    text-align: center;
    width: 1em;
  }
  .length-of-stay-input::placeholder {
    color: inherit;
    opacity: 1;
  }

  .length-of-stay-input:focus,
  .length-of-stay-input:hover {
    outline: none;
    border-color: currentColor;
    border-style: solid;
  }
  .length-of-stay-input:focus {
    cursor: text;
  }

  .check-in-input::placeholder {
    color: inherit;
    opacity: 1;
  }

  .check-in-input {
    background: transparent;
    border-color: transparent;
    border-radius: 0;
    border-width: 0 0 1px 0;
    color: #C6C6C6;
    cursor: pointer;
    display: inline-block;
    font-size: inherit;
    margin: 0 0.25em;
    text-align: center;
    width: 3.25em;
  }
  .check-in-input:focus,
  .check-in-input:hover {
    outline: none;
    border-color: currentColor;
    border-style: solid;
  }
  .check-in-input:focus {
    cursor: text;
  }

  .react-date-picker__calendar {
    color: black;
    font-size: 16px;
  }

  .react-date-picker__wrapper {
    border: none;
    color: #C6C6C6;
    font-size: inherit;
  }

  .react-date-picker__button svg {
    stroke: currentColor;
    width: auto;
    height: 1em;
  }

  .react-date-picker__button:enabled:hover .react-date-picker__button__icon,
  .react-date-picker__button:enabled:focus .react-date-picker__button__icon {
    color: white;
  }

  .react-date-picker__inputGroup__input::placeholder {
    opacity: 1;
    color: inherit;
  }

  .test,
  .end-date-input {
    background: transparent;
    color: inherit;
  }

  .submit-button {
    height: 1.4em;
    vertical-align: top;
    width: 1em;
  }

  .submit-button-image {
    height: 100%;
    width: 100%;
  }
`;

export const query = graphql`
  query BookingPageQuery {
    booking: sanityBooking {
      defaultStartDate
      text
      image {
        alt
        asset {
          _id
          fluid(maxWidth: 1500) {
            ...GatsbySanityImageFluid
          }
        }
      }
      mobileImage {
        alt
        asset {
          _id
          fluid(maxWidth: 1500) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
`

class BookingPage extends React.Component {
  constructor(props) {
    super(props);

    const defaultStart = props.data.booking.defaultStartDate || moment().format('YYYY-MM-DD')

    let defaultStartDateArray = defaultStart.split('-');

    let defaultStartDate = new Date(defaultStartDateArray[0], defaultStartDateArray[1] - 1, defaultStartDateArray[2]);
    let checkInDate = defaultStartDate;

    let lengthOfStay = '';

    const copy = new Date(defaultStartDate);
    copy.setDate(parseInt(copy.getDate()) + parseInt(lengthOfStay));
    let checkOutDate = copy;

    this.state = { defaultStartDate, checkInDate, lengthOfStay, checkOutDate };

    this.setCheckInDate = this.setCheckInDate.bind(this);
    this.handleLengthOfStayChange = this.handleLengthOfStayChange.bind(this);
  }

  setCheckInDate(newValue) {
    let lengthOfStay = this.state.lengthOfStay !== '' ? this.state.lengthOfStay : 1;
    let dateObject = {};

    if (newValue !== "") {
      dateObject = new Date(newValue);

      if (dateObject.getFullYear() === 2001 || dateObject.getFullYear() === 2000) {
        dateObject.setFullYear(new Date().getFullYear());
      }
    } else {
      dateObject = new Date(this.state.defaultStartDate);
    }

    const checkInDate = dateObject;

    let copy = new Date(dateObject);
    copy.setDate(parseInt(dateObject.getDate()) + parseInt(lengthOfStay));
    const checkOutDate = copy;

    this.setState({ checkInDateRaw: newValue, checkInDate, checkOutDate });
  }

  handleLengthOfStayChange(newValue) {
    let lengthOfStay = newValue !== '' ? newValue : 1;
    const checkInDate = new Date(this.state.checkInDate);

    let copy = new Date(checkInDate);
    copy.setDate(parseInt(copy.getDate()) + parseInt(lengthOfStay));

    const checkOutDate = copy;

    this.setState({ lengthOfStay: newValue, checkOutDate });
  }

  dateToYYYYMMDD(date) {
    let dateObj = Date.parse(date) ? new Date(date) : new Date();
    return dateObj.toISOString().slice(0, 10);
  }

  render() {
    const { image, mobileImage, text } = this.props.data.booking;

    // image data
    const bookingImage = image;
    const imageAsset = bookingImage.asset.fluid;
    const imageAlt = image.alt;
    const mobileBookingImage = mobileImage;
    const mobileImageAsset = mobileBookingImage.asset.fluid;
    const mobileImageAlt = mobileImage.alt;

    const bookingText = text;

    return (
      <Layout uri={this.props.uri}>
        <SEO />
        <BookingStyles>
          <div className="absolute top-0 left-0 bottom-0 right-0 z-0 object-cover h-full w-full">
            <MediaQuery maxWidth={767}>
              {(matches) => matches
                ? <Img className="absolute h-full" fluid={mobileImageAsset} alt={mobileImageAlt} />
                : <Img className="absolute h-full" fluid={imageAsset} alt={imageAlt} />
              }

            </MediaQuery>
          </div>
          <div className="with-bg relative z-10 w-full h-full max-w-screen-md flex justify-center items-center">
            <form className="booking-form sans-reg text-4xl px-3 md:text-5xl text-center" action="https://direct-book.com/piaule-catskill/properties/piaulecatskilldirect" target="_blank">
              {bookingText}
              <input
                autoComplete="off"
                className="length-of-stay-input"
                name="lengthOfStay"
                onChange={event => { this.handleLengthOfStayChange(event.target.value) }}
                placeholder="1"
                type="text"
                value={this.state.lengthOfStay}
              />
              days,<br />starting on
              <input
                className="check-in-input"
                onChange={event => { this.setCheckInDate(event.target.value) }}
                placeholder={this.state.defaultStartDate.toLocaleString('default', { month: 'short', day: 'numeric' })}
                type="text"
                value={this.state.checkInDateRaw}
              />
              <button type="submit" className="submit-button">
                <img className="submit-button-image" src={submitBtn} alt="Submit Button" />
              </button>
              <input className="test" name="checkInDate" type="hidden" readOnly value={this.dateToYYYYMMDD(this.state.checkInDate)} />
              <input name="checkOutDate" type="hidden" readOnly className="end-date-input" value={this.dateToYYYYMMDD(this.state.checkOutDate)} />
            </form>

          </div>
        </BookingStyles>
      </Layout>
    )
  }
}

export default BookingPage
